import { Modal, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactCompareImage from "react-compare-image";
import AppContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import Loader from "../Animations/Loader";
import { ICompareImages } from "./deck.gl.map";
import { env } from './../../env';
import Style from "./Map.module.css";
import {
	ZoomInOutlined, ZoomOutOutlined, DownloadOutlined, EditOutlined
} from '@ant-design/icons';
import { Button} from 'antd';
import UpdateEventForm from "./UpdateEventForm";
import { IEvent } from "../../interfaces/map.interfaces";
import UpdateSviEventForm from "./updateSviEventForm";

function CompareImages({
	images,
	closeModal,
	eventObject,
	refreshEvents,
	language
}: {
	images: ICompareImages;
	closeModal: Function;
	eventObject: IEvent;
	language: string,
	refreshEvents: Function;
}) {
	const appContext: IAppContext = useContext(AppContext);
	const shouldShowModal: boolean = !!images.eventAfter || !!images.eventBefore;
	const [tokenData, setTokenData] = useState({ forceRefresh: true, token: "" });
	const [modalSize, setModalSize] = useState("25%")
	const [visible, setVisible] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState(null);
	const [sviImageList, setSviImageList] = useState([])
	const [latestImage, setLatestImage] = useState()
	const [latestImageObject, setLatestImageObject] = useState()
	const [latestImageDate, setLatestImageDate]= useState()
	const [comaparableImageSvi, setComaprarableImageSvi] = useState()
	const [comaparableImageDate, setcomaprarableImageDate]= useState()


	useEffect(() => {
		appContext?.services?.firebaseService?.auth?.currentUser
			?.getIdToken()
			.then((value) => setTokenData({ forceRefresh: false, token: value }));
	}, []);

	let embeddedApp = appContext.projects.app === "svi" ? true : false

	useEffect(() => {
        if (embeddedApp) {
            getImages()
                .then((images) => {
                    if (!images || images.length === 0) {
                        console.warn("No images found");
                        return;
                    }

                    const events = [...(images[0].events || [])];

					const filteredEvents = events.filter((event) => event.properties.image !== "");
                    setSviImageList(events);

                    if (filteredEvents.length > 0) {
                        setLatestImage(filteredEvents[0].properties.id); 
                        setLatestImageDate(filteredEvents[0].properties.date.slice(0,10)); 
						setLatestImageObject(filteredEvents[0])
                        filteredEvents.shift(); 
						setComaprarableImageSvi(filteredEvents[0].properties.id)
                        setcomaprarableImageDate(filteredEvents[0].properties.date.slice(0,10)); 

                        setSviImageList(filteredEvents);
						
                    }
                })
                .catch((error) => {
                    console.error("Error fetching images:", error);
                });
        }
    }, [embeddedApp, appContext, eventObject]);

	// useEffect(() => {
    //     console.log("Updated sviImageList:", sviImageList);
    //     console.log("Updated latestImage:", latestImage);
    //     console.log("Updated comaparableImageSvi:", comaparableImageSvi);
    // }, [sviImageList, latestImage, comaparableImageSvi]);

	if (!shouldShowModal) {
		return null;
	}

	

	function getImages() {
		return appContext?.services.sviEventService.getMyData().then((data) => {
			console.log("called")
			let comparableEventsSVI = data.data.filter(event => event.id === eventObject.properties.locationId);
			return comparableEventsSVI; 
    	});
	}

	const handleSelect = (value) => {
        setSelectedValue(value);
		for (let i = 0; i < sviImageList.length; i++) {
			const image = sviImageList[i]


			if (image.properties.id == value && image.properties.image !== "") {
				setComaprarableImageSvi(image.properties.id)
				setcomaprarableImageDate(image.properties.date.slice(0,10))
			}
		}
	}

	const options = [];

    // Loop through sviImageList using a for loop
    for (let i = 0; i < sviImageList.length; i++) {
        const image = sviImageList[i];

        // Check if image and image.properties exist before rendering
        if (image.properties && image.properties.id && image.properties.date && image.properties.image !== "") {

            options.push(
                <Select.Option key={image.properties.id} value={image.properties.id}>
                    {image.properties.date.slice(0,10)}
                </Select.Option>
            );
        }
    }



	function downloadPngFile(url, filename = 'image.png') {
    // Fetch the image file from the URL
		fetch(url)
			.then(response => response.blob()) // Convert the response to a Blob
			.then(blob => {
				// Create a URL for the Blob
				const blobUrl = URL.createObjectURL(blob);
				
				// Create an anchor element
				const a = document.createElement('a');
				a.href = blobUrl;
				a.download = filename + ".png"; // Set the desired file name

				// Append the anchor to the document
				document.body.appendChild(a);

				// Trigger a click event on the anchor
				a.click();

				// Clean up: Remove the anchor and revoke the Blob URL
				document.body.removeChild(a);
				URL.revokeObjectURL(blobUrl);
			})
			.catch(error => console.error('Error downloading the file:', error));
	}

	const serverUrl = `${env.REACT_APP_SERVER_URL}`;
	// console.log(serverUrl)
    // console.log("comparing images")
	return (
		<div>
		<Modal
			centered
			getContainer={"#root"}
			onCancel={() => {
				closeModal()
				setModalSize("25%")
			}}
			visible={shouldShowModal}
			footer={null}
			width={modalSize}
			>
			{embeddedApp ? <div >
				<div style={{ width: 200 }}>
					<Select
						placeholder="Select an option"
						onSelect={handleSelect}
						style={{ width: "100%" }} // Full width
					>
						{options}
					</Select>
				</div>
					<ReactCompareImage
						leftImage={
							serverUrl +
							"/image/retrieve?eventId=" +
							comaparableImageSvi +
							"&token=" +
							tokenData.token
						}
						leftImageAlt="Before"
						leftImageLabel={comaparableImageDate}
						rightImage={
							serverUrl +
							"/image/retrieve?eventId=" +
							latestImage +
							"&token=" +
							tokenData.token
						}
						rightImageAlt="After"
						rightImageLabel={latestImageDate}
						skeleton={<Loader />}
						rightImageCss={{ height: "100%", width: "100%", objectFit: "fill" }}
						leftImageCss={{ height: "100%", width: "100%", objectFit: "fill" }}
						vertical={false}
						handleSize={images.sliderController ? 40 : null}
						sliderPositionPercentage={images.sliderController ? 0.5 : 0}
						sliderLineWidth={images.sliderController}
					/>
					<Button
							className={Style.enlargeCompareImage }
							onClick={() => {
								setModalSize("50%")
							}}
							disabled = {modalSize === "50%"}
						>
							<ZoomInOutlined />
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() => {
								setModalSize("25%")
							}}
							disabled = {modalSize === "25%"}
						>
							<ZoomOutOutlined />
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() =>{
								downloadPngFile(serverUrl +
								"/image/retrieve?eventId=" +
								comaparableImageSvi +
								"&token=" +
								tokenData.token, images.eventBefore?.id)
							}}
						>
							<DownloadOutlined /> Before Image
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() =>{
								downloadPngFile(serverUrl +
								"/image/retrieve?eventId=" +
								latestImage +
								"&token=" +
								tokenData.token, images.eventAfter?.id)
							}}
						>
							<DownloadOutlined /> Recent Image
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() => {
								setVisible(true);
							}}
						>
							<EditOutlined />
						</Button>
				</div>

				:  <div>
						<ReactCompareImage
							leftImage={
								serverUrl +
								"/image/retrieve?eventId=" +
								images.eventBefore?.id +
								"&token=" +
								tokenData.token
							}
							leftImageAlt="Before"
							leftImageLabel={`${images.eventBefore?.date.slice(0, 10)}`}
							rightImage={
								serverUrl +
								"/image/retrieve?eventId=" +
								images.eventAfter?.id +
								"&token=" +
								tokenData.token
							}
							rightImageAlt="After"
							rightImageLabel={`${images.eventAfter?.date.slice(0, 10)}`}
							skeleton={<Loader />}
							rightImageCss={{ height: "100%", width: "100%", objectFit: "fill" }}
							leftImageCss={{ height: "100%", width: "100%", objectFit: "fill" }}
							vertical={false}
							handleSize={images.sliderController ? 40 : null}
							sliderPositionPercentage={images.sliderController ? 0.5 : 0}
							sliderLineWidth={images.sliderController}
						/>
						<Button
							className={Style.enlargeCompareImage }
							onClick={() => {
								setModalSize("50%")
							}}
							disabled = {modalSize === "50%"}
						>
							<ZoomInOutlined />
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() => {
								setModalSize("25%")
							}}
							disabled = {modalSize === "25%"}
						>
							<ZoomOutOutlined />
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() =>{
								downloadPngFile(serverUrl +
								"/image/retrieve?eventId=" +
								images.eventBefore?.id +
								"&token=" +
								tokenData.token, images.eventBefore?.id)
							}}
						>
							<DownloadOutlined /><b>{language === "de" ? "Vorher-Bild" : " Before"}</b>
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() =>{
								downloadPngFile(serverUrl +
								"/image/retrieve?eventId=" +
								images.eventAfter?.id +
								"&token=" +
								tokenData.token, images.eventAfter?.id)
							}}
						>
							<DownloadOutlined /><b>{language === "de" ? "Aktuelles Bild" : "Recent"}</b> 
						</Button>
						<Button
							className={Style.minmizeComapreImage }
							onClick={() => {
								setVisible(true);
							}}
						>
							<EditOutlined />
						</Button>
					</div>}
			
			
			</Modal>
			{
			embeddedApp ? 
				<UpdateSviEventForm
					event={eventObject as IEvent}
					visible={visible}
					setVisible={setVisible}
					latestImageObject = {latestImageObject}
					refreshEvents={refreshEvents} /> : 
				<UpdateEventForm
					event={eventObject as IEvent}
					visible={visible}
					setVisible={setVisible}
					refreshEvents={refreshEvents} />
			}
			
      	</div>
		
		
	);
}

export default CompareImages;

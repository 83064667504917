import React from "react";
import { Link } from "react-router-dom";
import Style from "../../styles/Pages.module.css";
import withAuthorization from "../HOC/with.authorization";
import { IFirebaseAuthUser } from "../../interfaces/authentication.interfaces";
import AuthenticationValidatorLogic from "../../logic/authenticationValidatorLogic";
import * as ROUTES from "../../constants/routes";
import AppContext from "../../context/app.context";
import { BUTTON_PROPS } from "../../constants/general";
import { Button, Table } from "antd";
import { IServices } from "../../interfaces/services.interfaces";

interface IProps {}

interface IState {
  segment: any;
  refreshCounter: number;
  projectId: string
}

class SegmentsPage extends React.Component<IProps, IState> {
  static contextType = AppContext;

  private services: IServices;

  constructor(props: any, context: any) {
    super(props);

    const projectId = context.projects?.id || null;

    this.state = {
      segment: [],
      refreshCounter: 0,
      projectId
    };

    this.services = context.services;

    this.refreshData();
    console.log(this.state.segment)
  }

  refreshData() {
    this.services.segmentService
      .getMyData(this.state.projectId)
      .then((data: any) => {
        console.log("got pipeline data:");
        console.log(data);
        this.setState({
          segment: data?.data
        });
      })
      .catch((error: any) => {
        console.log("server error upon getting pipeline data!");
        console.log(error);
        (window as any).encounteredError = true;
      });
  }

  

  deletePipeline(pipeline: any) {
    if ((window as any)._userData?.add_pipeline) {
      let outerThis = this;
      this.services.pipelineService.delete(pipeline.properties.id).then(() => {
        outerThis.refreshData();
      });
    }
  }

  convertToGeoJSON(data) {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: data.properties,
        geometry: data.geometry
      }
    ]
  };
}

  // Function to download the GeoJSON file
  downloadGeoJSON(data, filename = `${data.properties.area}` + ".geojson") {
    // Convert data to a GeoJSON format
    const geoJSON = this.convertToGeoJSON(data);

    // Create a Blob from the GeoJSON string
    const blob = new Blob([JSON.stringify(geoJSON, null, 2)], { type: 'application/json' });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute with a filename
    link.download = filename;

    // Create a URL for the Blob and set it as the href attribute
    link.href = URL.createObjectURL(blob);

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  }

  updateNav() {
    window.setTimeout(function () {
      if ((window as any)._appIndex) {
        (window as any)._appIndex.forceUpdate();
      }
    }, 10);
    window.setTimeout(function () {
      if ((window as any)._appIndex) {
        (window as any)._appIndex.forceUpdate();
      }
    }, 1000);
  }

  render() {
    // state counter to enable refreshing this component
    const refresh = () =>
      this.setState({ refreshCounter: this.state.refreshCounter + 1 });

    // as long as the user data has not yet been retrieved...
    if ((this.context as any)?.userData == null) {
      // ... refresh this component once a second
      window.setTimeout(refresh, 1000);
    }

    let language = (this.context as any)?.userData?.language;

    let tableRows = [];
    let pipelines = this.state.segment;

    let columns: any = [
      {
        title: language === "de" ? "Pipeline-Name" : "Segment Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: language === "de" ? "Aktionen" : "Actions",
        dataIndex: "actions",
        key: "actions",
        align: "center",
      },
    ];

    for (let i = 0; i < pipelines.length; i++) {
      let pipName = pipelines[i]?.properties?.area;
      let pipLastUpdate = pipelines[i]?.properties?.lastUpdate;
      if (pipLastUpdate) {
        pipLastUpdate = pipLastUpdate.substring(0, 10);
      }
      /* we could here calculate the latitude and longitude of the pipeline:
      let lat = 0;
      let lng = 0;
      let coordinateAmount = 0;
      for (let c = 0; c < pipelines[i]?.geometry?.coordinates?.length; c++) {
        let coords = pipelines[i]?.geometry?.coordinates[c];
        for (let o = 0; o < coords.length; o++) {
          lat += coords[o][1];
          lng += coords[o][0];
          coordinateAmount++;
        }
      }
      if (coordinateAmount > 0) {
        lat /= coordinateAmount;
        lng /= coordinateAmount;
      }
      let linkTarget = ROUTES.MAP.route + "?lat=" + lat + "&lng=" + lng;
      */
      // but instead, we just tell the map view that it should show this pipeline,
      // and let it decide how best to do that:
      let linkTarget =
        ROUTES.MAP.route

      tableRows.push({
        key: i,
        name: pipName,
        actions: [
          <div>
            <Button
              className={Style.mapTooltipButton}
              {...BUTTON_PROPS}
              size="small"
              style={{ margin: "0 5px" }}
            >
              <Link to={linkTarget} onClick={this.updateNav}>
                {language === "de" ? "Anzeigen" : "Go to"}
              </Link>
            </Button>
            <Button
              className={Style.mapTooltipButton}
              {...BUTTON_PROPS}
              size="small"
              style={{ margin: "0 5px" }}
              onClick={() => {
                  this.downloadGeoJSON(pipelines[i]);
                }}
            >
              Download Geojson
            </Button>
            {(window as any)?._userData?.delete_pipeline && (
              <Button
                onClick={() => {
                  this.deletePipeline(pipelines[i]);
                }}
                className={Style.mapTooltipButton}
                {...BUTTON_PROPS}
                size="small"
                style={{ margin: "0 5px" }}
                type="danger"
                ghost
              >
                {language === "de" ? "Löschen" : "Delete"}
              </Button>
            )}
          </div>,
        ],
      });
    }

    function onChange(pagination, filters, sorter, extra) {
      console.log("params", pagination, filters, sorter, extra);
    }

    return (
      <div className={Style.formContainer}>
        <h2>{language === "de" ? "Pipelines" : "Segments"}</h2>
        <Table columns={columns} dataSource={tableRows} onChange={onChange} />
      </div>
    );
  }
}
const condition = (authUser: IFirebaseAuthUser) =>
  AuthenticationValidatorLogic.isAuthenticated(authUser);

export default withAuthorization(condition)(ROUTES.SIGN_IN.route)(
  SegmentsPage
);

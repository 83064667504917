import { Form, Input, Modal, Radio, Select } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useContext, useEffect, useState } from "react";
import { BUTTON_PROPS } from "../../constants/general";
import AppContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import { IEvent, IServerEvent } from "../../interfaces/map.interfaces";
import { getNewEventId } from "../../utils/event.utils";
import MapDataContext from "../MapData/map.data.context";
import UploadImage from "./UploadImage";

function UpdateSviEventForm({
  event,
  visible,
  setVisible,
  latestImageObject,
  refreshEvents,
}: {
  event: IEvent;
  visible: boolean;
  setVisible: Function;
  latestImageObject: any;
  refreshEvents: Function;
}) {
  const appContext: IAppContext = useContext(AppContext);
  const mapDataContext = useContext(MapDataContext);

  const [language, setLanguage] = useState<string>(null);
  const [subTypeEnabled, setSubTypeEnabled] = useState<boolean>(null);
  const [types, setTypes] = useState<string[]>();
  const [subTypes, setSubTypes] = useState<string[]>();
  const [author, setAuthor] = useState<string>();

  const [currentEventState, setCurrentEventState] = useState<string>();
  const [currentTypeState, setCurrentTypeState] = useState<string>();
  const [currentSubTypeState, setCurrentSubTypeState] = useState<string>();

  const [newEventState, setNewEventState] = useState<string>();
  const [newTypeState, setNewTypeState] = useState<string>();
  const [newSubTypeState, setNewSubTypeState] = useState<string>();
  const [newEventNote, setNewEventNote] = useState<string>("");
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<UploadFile>(null);
  const { Option } = Select;

  const comment = event.properties.comment
  const code = event.properties.code
  const area = event.properties.area
  const image = event.properties.image

  useEffect(() => {
    appContext.services.userService.getMyData().then((data) => {
      setLanguage(data.data.language);
      setAuthor(data.data.email);
    });
    appContext.services.projectService.getMyData().then((data) => {
      setSubTypeEnabled(data.data[0].subTypeEnabled);
      setSubTypes(data.data[0].subTypes);
      setTypes(data.data[0].types);
    });
  }, []);

  const getOptions = (options: string[]) => {
    let optionsArr = [];
    if (options) {
      for (const option of options) {
        optionsArr.push(
          <Option key={option} value={option}>
            {option}
          </Option>
        );
      }
    }

    return optionsArr;
  };

  const onEditEvent = (
    previousEvent: IServerEvent,
    newType: string,
    newSubType: string,
    newState: string,
    newNote: string,
    author: string
  ) => {
    appContext.services.sviEventService.updateEvent(
      previousEvent,
      newType,
      newSubType,
      newState,
      newNote,
      author, 
      comment,
      previousEvent.properties.date,
      code,
      area
    );

    appContext.services.imageService.uploadImage(
      imageFile?.originFileObj as File,
      previousEvent.properties.image
    );
    setTimeout(() => {
      refreshEvents();
    }, 1000);
  };

  const handleOk = (event: IEvent) => {
    setConfirmLoading(true);
    setTimeout(() => {
      let previousEvent = {
        properties: {
          ...event.properties,
          id: getNewEventId(event.properties.id),
          image: imageFile ? getNewEventId(event.properties.id) + ".png" : "",
        },
        geometry: {
          type: event.type,
          coordinates: event.coordinates,
        },
      };

      onEditEvent(
        previousEvent,
        newTypeState,
        newSubTypeState,
        newEventState,
        newEventNote,
        author
      );

      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setNewSubTypeState("");
    setNewTypeState("");
    setNewEventState("");
    setNewEventNote("");
    setCurrentEventState("");
    setCurrentTypeState("");
    setCurrentSubTypeState("");
    setVisible(false);
    setImageFile(null);
  };

  useEffect(() => {
    if (!visible) return;
    setNewSubTypeState(event.properties.subType ?? "");
    setNewTypeState(event.properties.type);
    setNewEventState(event.properties.state);
    setCurrentEventState(event.properties.state);
    setCurrentTypeState(event.properties.type);
    setCurrentSubTypeState(event.properties.subType ?? "");
    setImageFile(null);
  }, [event, visible]);

  return (
    <Modal
      title="Add Note"
      className="MapDeckComment"
      centered
      okText={"Submit"}
      visible={visible}
      onOk={() => {
        handleOk(event);
      }}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{
        type: "primary",
        shape: "round",
        size: "small",
        disabled:
          // !(currentEventState === newEventState) ||
          // !(currentSubTypeState === newSubTypeState) ||
          // !(currentTypeState === newTypeState) ||
          !newEventNote
      }}
      cancelButtonProps={{
        ...BUTTON_PROPS,
        type: "danger",
        shape: "round",
        size: "small",
      }}
    >
      <Form onClick={(e) => e.stopPropagation()}>
        {/* <Form.Item label={language === "de" ? "Status" : "State"}>
          <Radio.Group
            defaultValue={newEventState}
            onChange={(e) => setNewEventState(e.target.value)}
            value={newEventState}
          >
            <Radio.Button value={"active"}>
              {language === "de" ? "Aktiv" : "Active"}
            </Radio.Button>
            <Radio.Button value={"only report change"}>
              {language === "de" ? "Nur Änderung Melden" : "Only Report Change"}
            </Radio.Button>
            <Radio.Button value={"archived"}>
              {language === "de" ? "Archiv" : "Archive"}
            </Radio.Button>
            <Radio.Button value={"do not report"}>
              {language === "de" ? "Nicht Mehr Melden" : "Do Not Report"}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={language === "de" ? "Typ" : "Type"}>
          <Select
            style={{ width: 200 }}
            defaultValue={newTypeState}
            onChange={(value) => setNewTypeState(value)}
            value={newTypeState}
          >
            {getOptions(types)}
          </Select>
        </Form.Item> */}
        {/* <span hidden={!subTypeEnabled}>
          <Form.Item label={language === "de" ? "Sub Typ" : "SubType"}>
            <Select
              style={{ width: 200 }}
              defaultValue={newSubTypeState}
              onChange={(value) => setNewSubTypeState(value)}
              value={newSubTypeState}
            >
              {getOptions(subTypes)}
            </Select>
          </Form.Item>
        </span> */}
        <Form.Item label="Note">
          <Input
            placeholder = "Write your Note..."
            value={newEventNote}
            onChange={(e) => setNewEventNote(e.target.value)}
          />
        </Form.Item>
        {/* <Form.Item label={language === "de" ? "Bearbeiter" : "Editor"}>
          <Input
            placeholder={language === "de" ? "Name des Autors" : "Author Name"}
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </Form.Item> */}
      </Form>
      {/* <UploadImage
        setFile={setImageFile}
        file={imageFile?.originFileObj as File}
      /> */}
    </Modal>
  );
}

export default UpdateSviEventForm;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer, IconLayer, TextLayer } from "@deck.gl/layers";
import { LinearInterpolator } from "@deck.gl/core";
import {
  _MapContext as MapContext,
  NavigationControl,
  StaticMap,
} from "react-map-gl";
import Style from "./Map.module.css";
import { ReactComponent as SatelliteIcon } from "../../assets/satellite.svg";
import { ReactComponent as WorldIcon } from "../../assets/world.svg";
import DroneIconPng from "../../assets/drone.png";
import { Icon, Switch, Checkbox, Select } from "antd";
import {
  ILocation,
  IServerEvent,
  IEvent,
  IEventProperties,
  IInteractionState,
  IPipelineData,
  IViewState,
  TRANSITION_EVENTS,
  IPipelineProperties,
  IDroneImage,
  IDronePath,
  IDroneJob,
} from "../../interfaces/map.interfaces";
import MapComponentContext from "./map.component.context";
//import moment from "moment-timezone";
import {
  INCIDENT_ICON_SIZE,
  PIPELINE_COLOR_PRIMARY,
  PIPELINE_COLOR_SECONDARY,
  PIPELINE_HIGHLIGHT_COLOR,
  SEGMENT_COLOR,
  GRID_AREA_COLOR,
  PIPELINE_HIGHLIGHT_COLOR_2,
} from "../../constants/map.consts";
import { Moment } from "moment-timezone/moment-timezone";
import AppContext from "../../context/app.context";
import { IAppContext } from "../../interfaces/context.interfaces";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { DrawLineStringMode } from "@nebula.gl/edit-modes";
import MapLogic from "../../logic/map.logic";
import {
  calcDuration,
  flyToEvent,
  getEventIcon,
  getImageComparisonEvents,
  getLocationToShow,
  ourEasing,
  zoomIntoEvent,
} from "./map.utils";
import PipelineTooltip from "./PipelineTooltip";
import CompareImages from "./CompareImages";
import ContextMenu from "./ContextMenu";
import EventTooltip from "./EventTooltip";
import AddNewHeliEvent from "./AddNewHeliEvent";
import { env } from "./../../env";
import VideoModal from "./VideoModal";
import ImageModal from "./ImageModal";
import { DataFilterExtension } from "@deck.gl/extensions";

interface IInitialView {
  zoom: number;
  longitude: number;
  latitude: number;
}

interface IMapProps {
  pipelineData: IPipelineData | undefined;
  locationsData: ILocation[];
  pipelineLastCheckedUtc: Moment | undefined;
  initialView: IInitialView | undefined;
}

export interface IDeckGlInfoObject {
  index: number;
  x: number;
  y: number;
  object: any;
  coordinate: any;
}

export interface ICompareImages {
  eventBefore?: IEventProperties;
  eventAfter?: IEventProperties;
  sliderController?: number;
}

interface IDragOrig {
  dragStartX: number;
  dragStartY: number;
  origX: number;
  origY: number;
}

const DEFAULT_VIEW_ANGLE = {
  pitch: 0,
  bearing: 0,
};

const normalMapStyle = "mapbox://styles/mapbox/outdoors-v11";
const satelliteMapStyle = "mapbox://styles/mapbox/satellite-v9";

const serverEventToEvent = function (serverEvent: IServerEvent): IEvent {
  let result = {
    type: "Point",
    opacity: 1,
    coordinates: serverEvent.geometry.coordinates,
    properties: serverEvent.properties,
  } as IEvent;

  // we use this later on to check that an object we clicked on is an event,
  // so we ensure that this type is really written here - rather than whatever
  // nonsense might come from the backend ^^'
  return result;
};

// once a second, check if the map has loaded the non-working compass icon, and if so,
// replace it with the working one
window.setInterval(function () {
  var btns = document.getElementsByClassName("mapboxgl-ctrl-compass-arrow");
  if (btns.length > 0) {
    btns[0].className = "mapboxgl-ctrl-icon mapboxgl-ctrl-compass";
  }
}, 1000);

export default function Map({
  initialView,
  pipelineData,
  locationsData: _locationsData,
  pipelineLastCheckedUtc,
}: IMapProps) {
  const appContext: IAppContext = useContext(AppContext);

  const [language, setLanguage] = useState<string>(null);
  const [labelEnabled, setLabelEnabled] = useState<boolean>(null);
  const [enableHeli, setEnableHeli] = useState<boolean>(false);

  const [showSegment, setShowSegment] = useState(false);
  const [segmentsData, setSegmentsData] = useState();
  const [showGridArea, setShowGridArea] = useState(false);
  const [gridAreaData, setGridAreaData] = useState([]);
  const [currentlyEditingPipeline, setCurrentlyEditingPipeline] =
    useState<boolean>(false);
  const [locationsData, setLocationsData] = useState(_locationsData);

  const [videoUrl, setVideoUrl] = useState("");
  const [showVideoModal, setShowVideoModal] = useState(false);
  // Drone Jobs and Paths
  const [droneJobs, setDroneJobs] = useState<IDroneJob[]>([]);
  const [selectedJob, setSelectedJob] = useState<IDroneJob>();
  // const [selectedDronePaths, setSelectedDronePaths] = useState([]);
  const [showDronePaths, setShowDronePaths] = useState(false);
  const [dronePaths, setdronePaths] = useState<IDronePath[]>([]);
  // Drone Images
  const [showDroneImages, setShowDroneImages] = useState(false);
  const [droneImages, setDroneImages] = useState([]);
  const [showDroneImage, setShowDroneImage] = useState(false);
  const [activeDroneImage, setActiveDroneImage] = useState<IDroneImage>();
  const [droneImageUrl, setDroneImageUrl] = useState<string>();

  const refreshEvents = useCallback(() => {
    let selectedService =
      appContext?.projects?.app === "svi"
        ? appContext?.services.sviEventService
        : appContext?.services.eventService;

    selectedService.getMyData().then((data) => {
      setLocationsData(data?.data);
    });
  }, [appContext?.services.eventService, appContext?.services.sviEventService]);

  const getSegmentsData = () => {
    if (!segmentsData) {
      const projectId = appContext.projects?.id;
      if (projectId) {
        appContext?.services.segmentService
          .getMyData(projectId)
          .then((data) => {
            setSegmentsData(data.data);
            setShowSegment(true);
          });
      }
    } else {
      setShowSegment(true);
    }
  };
  const getGridAreasData = () => {
    if (!gridAreaData.length) {
      const projectId = appContext.projects?.id;
      if (projectId) {
        appContext?.services.gridService.getMyData(projectId).then((data) => {
          setGridAreaData(data.data);
          setShowGridArea(true);
        });
      }
    } else {
      setShowGridArea(true);
    }
  };

  const getDronePaths = (projectId: string, selectedJob: string) => {
    if (projectId) {
      appContext?.services.droneService
        .getDronePaths(projectId, selectedJob)
        .then((data) => {
          setdronePaths(data.data);
          setShowDronePaths(true);
        });
    }
  };

  const getDroneJobs = () => {
    const projectId = appContext.projects?.id;
    if (projectId) {
      appContext?.services.droneService.getDroneJobs(projectId).then((data) => {
        setDroneJobs(data.data);
        setShowDronePaths(true);
      });
    }
  };

  const getDroneImages = () => {
    if (!droneImages.length) {
      const projectId = appContext.projects?.id;
      if (projectId) {
        appContext?.services.droneService.getImages(projectId).then((data) => {
          setDroneImages(data.data);
          setShowDroneImages(true);
        });
      }
    } else {
      setShowDroneImages(true);
    }
  };

  const getDroneImageUrl = (obj: any) => {
    if (obj.properties.key) {
      setActiveDroneImage(obj);
      appContext.services.droneService
        .getImageUrl(obj.properties.key)
        .then((data) => {
          setDroneImageUrl(data.data);
          setShowDroneImage(true);
        });
    }
  };

  useEffect(() => {
    appContext.services.userService.getMyData().then((data) => {
      setLanguage(data.data.language);
    });
    refreshEvents();
  }, [appContext.services.userService, refreshEvents]);

  // set center of demo data as default location until a pipeline is actually loaded from the backend
  let initView: IInitialView = {
    zoom: 10,
    longitude: 9.9794045,
    latitude: 51.703047,
  };

  // re-set the initial view based on the pipeline view, as supplied by the GenericMapData
  if (initialView) {
    initView = initialView;
  }

  // finally, if we are supposed to show a particular location instead, set the initial view based on that
  const [locationToShow, setLocationToShow] = useState<ILocation>();

  if (locationToShow) {
    initView = MapLogic.fitMapToEventBoundingBox(
      locationToShow.events[0].geometry.coordinates
    );
  }

  const initialViewState: IViewState = {
    ...DEFAULT_VIEW_ANGLE,
    ...initView,
  };
  const [previousViewState, setPreviousViewState] =
    useState<IViewState>(initialViewState);
  const [viewState, setViewState] = useState<IViewState>(initialViewState);
  const [mapStyle, setMapStyle] = useState<string>(normalMapStyle);
  const [embeddedApp, setEmbeddedApp] = useState<boolean>(false);
  const [preventNextGenericMapClick, setPreventNextGenericMapClick] =
    useState<boolean>(false);
  const [clickedZoomBtn, setClickedZoomBtn] = useState<boolean>(false);
  const [clickedObject, setClickedObject] = useState<IDeckGlInfoObject | null>(
    null
  );

  useEffect(() => {
    if (appContext.projects?.app === "svi") {
      setEmbeddedApp(true);
      setShowSegment(true);
      getSegmentsData();
      toggleMapStyle();
    }
  }, [appContext.projects?.app]);

  // state of the geojson of the editable pipeline
  const [editGeoJson, setEditGeoJson] = useState({
    type: "FeatureCollection",
    features: [],
  });

  /* setHoveredObject is only used to solve a flickering bug when hovering over IconLayer */
  /* eslint-disable-next-line */
  const [hoveredObject, setHoveredObject] = useState<IDeckGlInfoObject | null>({
    index: 0,
    x: 0,
    y: 0,
    object: null,
    coordinate: [0, 0],
  });
  const [compareImages, setCompareImages] = useState<ICompareImages>({});

  const closeTooltip = useCallback(() => {
    setClickedObject(null);
    setClickedZoomBtn(true);
  }, []);

  const [currentPipelineData, setCurrentPipelineData] = useState<
    IPipelineData | undefined
  >(pipelineData);

  const toggleMapStyle = () => {
    const newMapStyle =
      mapStyle === normalMapStyle ? satelliteMapStyle : normalMapStyle;
    setMapStyle(newMapStyle);
  };

  const closeImageCompare = () => {
    setCompareImages({});
  };

  const openImageCompare = (compareImages: ICompareImages) => {
    setCompareImages(compareImages);
  };

  const zoomIn = (event: IEvent) => {
    !clickedZoomBtn && setPreviousViewState({ ...viewState });
    setViewState(zoomIntoEvent(event, viewState));
  };

  useEffect(() => {
    setLocationToShow(getLocationToShow(locationsData));
    let locationToShow = getLocationToShow(locationsData);
    if (locationToShow) {
      let events = locationToShow.events;
      let imageCompare = getImageComparisonEvents(locationToShow);
      let event = serverEventToEvent(events[0]);

      // open the event callout
      setClickedObject({
        index: 0,
        x: 0,
        y: 0,
        object: event,
        coordinate: {},
      });

      // open the event image comparison view
      openImageCompare(imageCompare);
      setViewState(zoomIntoEvent(event, viewState));

      //   if (appContext.projects?.app === "svi-x") {

      //   setEmbeddedApp(true)
      //   setShowSegment(true)
      //   getSegmentsData()
      //   toggleMapStyle()
      // }
    }
  }, [locationsData]);

  const addPipeline = (e) => {
    e.stopPropagation();

    setCurrentlyEditingPipeline(true);
  };

  const cancelEditing = (e) => {
    e.stopPropagation();

    // reset edited pipeline
    setEditGeoJson({
      type: "FeatureCollection",
      features: [],
    });

    // stop editing
    setCurrentlyEditingPipeline(false);
  };

  const savePipeline = (e) => {
    e.stopPropagation();
    if ((window as any).userData?.add_pipeline) {
      appContext.services.pipelineService.addPipeline(editGeoJson).then(() => {
        // instead of reloading the whole page:
        // window.location.reload();

        // ... let's try to just reload the pipeline data:
        appContext.services.pipelineService
          .getMyData()
          .then((data: any) => {
            console.log("got refreshed pipeline data after saving:");
            setCurrentPipelineData(data?.data);
          })
          .catch((error: any) => {
            console.log("server error upon getting updated pipeline data!");
            console.log(error);
            (window as any).encounteredError = true;
          });

        // ... and then stopping the edit mode:
        cancelEditing(e);
      });
    }
  };

  const pipeline = new GeoJsonLayer({
    id: "pipeline-layer",
    data: currentPipelineData,
    parameters: {
      depthTest: false,
    },
    pickable: true,
    autoHighlight: true,
    highlightColor: ({ object }) => {
      return object?.properties.objectType === "pipelineSegment"
        ? PIPELINE_HIGHLIGHT_COLOR
        : PIPELINE_HIGHLIGHT_COLOR_2;
    },
    lineWidthScale: 1,
    lineWidthMinPixels: 5,
    getLineColor: ({
      properties: { objectType },
    }: {
      properties: IPipelineProperties;
    }) => {
      return objectType === "pipelineSegment"
        ? PIPELINE_COLOR_PRIMARY
        : PIPELINE_COLOR_SECONDARY;
    },
    getLineWidth: 3,
  } as any);

  const segmentLayer = new GeoJsonLayer({
    id: "segment-layer",
    data: segmentsData,
    pickable: false,
    autoHighlight: true,
    lineWidthScale: 1,
    stroked: true,
    lineWidthMinPixels: 1,
    // lineWidthMaxPixels: 4,
    getLineColor: SEGMENT_COLOR,
    GRID_AREA_COLOR,
    getFillColor: () => [0, 0, 0, 0],
  } as any);

  const segmentText = new TextLayer({
    id: "grid-text-layer",
    data: segmentsData,
    pickable: true,
    getPosition: (area) => MapLogic.calculatePolygonCenter(area),
    getText: (area) => area.properties.area,
    getSize: 15,
    background: true,
    backgroundPadding: [3, 3, 3, 3],
    getBackgroundColor: [255, 255, 255, 160],
  } as any);

  const gridAreasLayer = new GeoJsonLayer({
    id: "grid-layer",
    data: gridAreaData,
    pickable: false,
    autoHighlight: true,
    lineWidthScale: 1,
    lineWidthMinPixels: 2,
    getLineColor: GRID_AREA_COLOR,
    getFillColor: () => [0, 0, 0, 0],
  } as any);

  const dronePathsLayer = new GeoJsonLayer({
    id: "drone-layer",
    data: dronePaths,
    pickable: true,
    autoHighlight: true,
    lineWidthScale: 1,
    lineWidthMinPixels: 4,
    getLineColor: [0, 255 / 1.5, 0, 255],
    highlightColor: () => [0, 255, 0, 255],
  } as any);

  const gridAreasText = new TextLayer({
    id: "grid-text-layer",
    data: gridAreaData,
    pickable: true,
    getPosition: (area) => MapLogic.calculatePolygonCenter(area),
    getText: (area) => area.properties.name,
    getSize: 15,
    background: true,
    backgroundPadding: [3, 3, 3, 3],
    getBackgroundColor: [255, 255, 255, 160],
  } as any);

  const eventsData = useMemo(() => {
    return locationsData
      .map(({ events }) => {
        return serverEventToEvent(events[0]);
      })
      .filter(
        ({ properties: { state } }) =>
          state !== "archived" &&
          state !== "deleted" &&
          state !== "observe" &&
          state !== "closed" &&
          state !== "removed"
      );
  }, [locationsData]);

  const [eventsFilter, setEventsFilter] = useState<{
    state: "active" | "only report change" | "do not report" | "all";
    reportType: "All" | "Processed Reports" | "Edited Reports";
  }>({
    state: "all",
    reportType: "All",
  });

  const droneImageLayer = new IconLayer({
    id: "drone-images-layer",
    data: droneImages,
    autoHighlight: true,
    pickable: true,
    onClick: (obj) => {
      getDroneImageUrl(obj.object);
    },
    iconAtlas: DroneIconPng,
    iconMapping: {
      marker: { x: 0, y: 0, width: 512, height: 512, mask: true },
    },
    sizeScale: 4,
    getIcon: () => "marker",
    minSize: 4,
    getColor: (d: IDroneImage) => {
      return d._id === activeDroneImage?._id
        ? [255, 255 / 4, 255 / 8, 255]
        : [255 / 8, 255 / 4, 255, 255];
    },
    getSize: INCIDENT_ICON_SIZE * Math.log(viewState.zoom / INCIDENT_ICON_SIZE),
    getPosition: (d: IDroneImage) => d.geometry.coordinates,
    // getFilterValue: (d: IDroneImage) => d.geometry.coordinates,
    // // filterRange: [100, 5000],
    // filterSoftRange: [0.2, 1],
    // filterTransformSize: true,
    // filterTransformColor: true,
    // extensions: [new DataFilterExtension({ filterSize: 1 })],
  } as any);

  const events = new IconLayer({
    id: "events-layer",
    data: eventsData
      .filter((event) =>
        eventsFilter.state === "all"
          ? true
          : eventsFilter.state === "active"
          ? ["active", "new"].includes(event.properties.state)
          : event.properties.state === eventsFilter.state
      )
      .filter((event) =>
        eventsFilter.reportType === "All"
          ? true
          : eventsFilter.reportType === "Processed Reports"
          ? event.properties.author.endsWith("@supervision.earth")
          : !event.properties.author.endsWith("@supervision.earth")
      ),
    pickable: true,
    autoHighlight: true,
    iconAtlas: null,
    getIcon: (d: IEvent) => {
      const { author, id, state, type } = d.properties;

      return {
        url: getEventIcon(id, state, type, author),
        width: 384,
        height: 384,
      };
    },
    sizeScale: 16,
    getSize: 8,
    getPosition: (d: IEvent) => d.coordinates,
    getFilterValue: (d: IEvent) => d.opacity,
    filterRange: [0, 1],
    filterSoftRange: [0.2, 1],
    filterTransformSize: true,
    filterTransformColor: true,
    extensions: [new DataFilterExtension({ filterSize: 1 })],
  } as any);

  const locationIdLayer = new TextLayer({
    id: "text-layer",
    data: eventsData,
    pickable: true,
    getPosition: (event) => event.coordinates,
    getText: (event) => event.properties.locationId,
    getSize: 15,
    background: true,
    backgroundPadding: [3, 3, 3, 3],
    getBackgroundColor: [255, 255, 255, 160],
  } as any);

  const getMapApi = useMemo(() => ({ closeTooltip }), [closeTooltip]);

  let layers = [
    pipeline,
    events,
    labelEnabled && locationIdLayer,
    showSegment && segmentLayer,
    showSegment && segmentText,
    showGridArea && gridAreasLayer,
    showGridArea && gridAreasText,
    showDronePaths && dronePathsLayer,
    showDroneImages && droneImageLayer,
  ];

  if (currentlyEditingPipeline) {
    //@ts-ignore
    const pipelineEditingLayer = new EditableGeoJsonLayer({
      id: "edit-pipeline-layer",
      data: editGeoJson,
      mode: DrawLineStringMode,
      onEdit: (data: any) => {
        setEditGeoJson(data.updatedData);
      },
    } as any);

    layers = [pipeline, events, pipelineEditingLayer];
  }

  return (
    <ContextMenu point={hoveredObject.coordinate} viewState={viewState}>
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <DeckGL
          width="100%"
          height="100%"
          viewState={viewState}
          onViewStateChange={({
            viewState,
            interactionState,
            oldViewState,
          }: {
            viewState: IViewState;
            interactionState: IInteractionState;
            oldViewState: IViewState;
          }) => {
            setViewState(viewState);
          }}
          controller={true}
          layers={layers}
          ContextProvider={MapContext.Provider}
          useDevicePixels={false}
          onClick={(info, event: any) => {
            setHoveredObject({
              index: info.index,
              x: info.x,
              y: info.y,
              coordinate: info.coordinate,
              object: info.object as IEvent,
            });

            // if we just clicked on a button in an event modal...
            if (preventNextGenericMapClick) {
              setPreventNextGenericMapClick(false);
              // ... do nothing!
              return;
            }

            let newViewState = viewState;
            let actuallyChangedViewState = false;

            if (clickedZoomBtn) {
              newViewState = {
                ...previousViewState,
                transitionInterpolator: new LinearInterpolator(),
                transitionInterruption: TRANSITION_EVENTS.BREAK,
                transitionEasing: ourEasing,
              };
              newViewState.transitionDuration = calcDuration(
                previousViewState,
                newViewState
              );
              setClickedZoomBtn(false);
              actuallyChangedViewState = true;
            }

            if (info != null) {
              let newX = info.x;
              let newY = info.y;
              let infoObj = info.object as IEvent;
              if (infoObj != null) {
                if (infoObj.type === "Point") {
                  newViewState = flyToEvent(infoObj, newViewState);
                  // instead of setting the element to the x and y of the click (which will no longer be accurate
                  // after we fly to the clicked event anyway), we set x and y to the middle of the screen...
                  newX = document.getElementById("root").clientWidth / 2;
                  newY = document.getElementById("root").clientHeight / 2;
                  actuallyChangedViewState = true;
                }

                setClickedObject({
                  index: info.index,
                  x: newX,
                  y: newY,
                  object: infoObj,
                  coordinate: {},
                });

                // hide tooltips on zoom button press (as off-center zooming will lead to the tooltip
                // being aaaanywhere on the map, but not in the correct place!)
                let zoomIns = document.querySelectorAll(
                  ".mapboxgl-ctrl-zoom-in"
                );
                for (let i = 0; i < zoomIns.length; i++) {
                  zoomIns[i].addEventListener("click", function () {
                    closeTooltip();
                  });
                }
                let zoomOuts = document.querySelectorAll(
                  ".mapboxgl-ctrl-zoom-out"
                );
                for (let i = 0; i < zoomOuts.length; i++) {
                  zoomOuts[i].addEventListener("click", function () {
                    closeTooltip();
                  });
                }
              }
            }

            if (actuallyChangedViewState) {
              try {
                setViewState(newViewState);
              } catch (err) {
                console.log(
                  "Oh no, got deck.gl exception - let's refresh the page, but we are unhappy..."
                );
                // TODO refresh
              }
            }
          }}
        >
          <StaticMap
            mapboxApiAccessToken={env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle={mapStyle}
            preventStyleDiffing={true}
          />
          <MapComponentContext.Provider value={getMapApi}>
            {<PipelineTooltip clickedObject={clickedObject} />}
            {
              <EventTooltip
                eventObject={clickedObject?.object}
                eventsData={locationsData}
                refreshEvents={refreshEvents}
                zoomIn={zoomIn}
                openImageCompare={openImageCompare}
                setVideoUrl={setVideoUrl}
                showVideoModal={showVideoModal}
                setShowVideoModal={setShowVideoModal}
              />
            }
            {
              <CompareImages
                images={compareImages}
                closeModal={closeImageCompare}
                eventObject={clickedObject?.object}
                refreshEvents={refreshEvents}
                language={language}
              />
            }
            {
              <AddNewHeliEvent
                enabled={enableHeli}
                setEnableHeli={setEnableHeli}
                refreshEvents={refreshEvents}
                event={hoveredObject}
              />
            }
            {droneImageUrl && (
              <ImageModal
                open={showDroneImage}
                setOpen={setShowDroneImage}
                imageUrl={droneImageUrl}
                setImageUrl={setDroneImageUrl}
              />
            )}
            {videoUrl && (
              <VideoModal
                open={showVideoModal}
                setOpen={setShowVideoModal}
                videoUrl={videoUrl}
                setVideoUrl={setVideoUrl}
              />
            )}
            <Icon
              onClick={toggleMapStyle}
              className={Style.mapStyleButton}
              component={
                mapStyle === normalMapStyle
                  ? () => <SatelliteIcon className={Style.mapButtonIcon} />
                  : () => <WorldIcon className={Style.mapButtonIcon} />
              }
            />
          </MapComponentContext.Provider>
          <div className={Style.mapControl}>
            <NavigationControl onViewportChange={setViewState} />
          </div>
          <div>
            {currentlyEditingPipeline ? (
              <div>
                <div
                  className={Style.topRightMainButton}
                  onClick={savePipeline}
                >
                  {language === "de" ? "Pipeline speichern" : "Save Pipeline"}
                </div>
                <div
                  className={Style.topRightCancelButton}
                  onClick={cancelEditing}
                >
                  {language === "de" ? "Abbrechen" : "Cancel"}
                </div>
                <div className={Style.bottomBannerContainer}>
                  <span className={Style.bottomBanner}>
                    <i>{language === "de" ? "Anmerkung" : "Note"}:</i>&nbsp;
                    {language === "de"
                      ? "Pipeline-Segmente können per Doppelklick abgeschlossen werden."
                      : "You can use a double click to finalize a pipeline segment."}
                  </span>
                </div>
              </div>
            ) : embeddedApp ? null : (
              <button
                className={Style.topRightMainButton}
                style={
                  !(window as any)._userData.add_pipeline
                    ? { background: "gray", cursor: "not-allowed" }
                    : { outline: "none", border: "0px" }
                }
                disabled={!(window as any)._userData.add_pipeline}
                onClick={addPipeline}
              >
                {embeddedApp
                  ? "Add AOI"
                  : language === "de"
                  ? "Pipeline hinzufügen"
                  : "Add Pipeline"}
              </button>
            )}
            <div
              className={Style.topRightLocationIDSwitch}
              style={{
                background: "#8c8c8c",
                borderColor: "black",
              }}
            >
              {/* Location ID */}
              {language === "de" ? "Ereigniss-ID anzeigen" : "Location ID"}
              <Switch
                style={{ margin: "0 .5rem" }}
                onChange={() => setLabelEnabled(!labelEnabled)}
              />
            </div>
            {embeddedApp ? null : (
              <div
              className={Style.topRightLocationIDSwitch}
              style={{
                background: "#8c8c8c",
                borderColor: "black",
                marginTop: "2rem",
              }}
            >
              {/* Add Event */}
              {language === "de" ? "Ereigniss hinzufügen" : "Add Event"}
              <Switch
                style={{ margin: "0 .5rem" }}
                onChange={(checked) => {
                  setEnableHeli(checked);
                }}
                checked={enableHeli}
              />
            </div>
            )}
            
            <div
              className={Style.topRightLocationIDSwitch}
              style={{
                background: "#8c8c8c",
                borderColor: "black",
                marginTop: "4rem",
              }}
            >
              {/* Monitored area */}
              {language === "de" ? "Überwachter Bereich" : "Monitored area"}
              <Switch
                style={{ margin: "0 .5rem" }}
                checked={showSegment}
                disabled={!appContext.projects?.id}
                onChange={async (checked) => {
                  if (checked) {
                    getSegmentsData();
                  } else {
                    setShowSegment(false);
                  }
                }}
              />
            </div>
            {embeddedApp ? null : (
              <div
                className={Style.topRightLocationIDSwitch}
                style={{
                  background: "#8c8c8c",
                  borderColor: "black",
                  marginTop: "6rem",
                }}
              >
                {/* Monitored area */}
                {embeddedApp
                  ? "AOIs Area"
                  : language === "de"
                  ? "Netzbereiche"
                  : "Grid area"}

                <Switch
                  style={{ margin: "0 .5rem" }}
                  checked={showGridArea}
                  disabled={!appContext.projects?.id}
                  onChange={async (checked) => {
                    if (checked) {
                      getGridAreasData();
                    } else {
                      setShowGridArea(false);
                    }
                  }}
                />
              </div>
            )}

            {embeddedApp ? null : (
              <div
              className={Style.topRightLocationIDSwitch}
              style={{
                width: "160px",
                background: "#8c8c8c",
                borderColor: "black",
                marginTop: "8rem",
                paddingBottom: ".5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <b>{language === "de" ? "Events filtern" : "Events filter"}</b>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <b>{language === "de" ? "Status" : "State"}</b>
                  <Select
                    dropdownStyle={{
                      background: "#8c8c8c",
                      borderColor: "black",
                      position: "absolute",
                    }}
                    style={{ width: "150px" }}
                    dropdownMenuStyle={{
                      position: "relative",
                      background: "#8c8c8c",
                    }}
                    defaultValue={eventsFilter.state}
                    onChange={(value) => {
                      setEventsFilter((p) => ({
                        ...p,
                        state: value as (typeof eventsFilter)["state"],
                      }));
                    }}
                  >
                    {[
                      ["all", "all"],
                      ["active", "Aktiv"],
                      ["only report change", "Nur Änderung Melden"],
                      ["do not report", "Nicht mehr melden"],
                    ].map((state, idx) => (
                      <Select.Option key={idx} value={state[0]}>
                        {language === "de" ? state[1] : state[0]}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <b>{language === "de" ? "Bearbeitet" : "Edited"}</b>
                  <Select
                    dropdownStyle={{
                      background: "#8c8c8c",
                      borderColor: "black",
                      position: "absolute",
                    }}
                    style={{ width: "150px" }}
                    dropdownMenuStyle={{
                      position: "relative",
                      background: "#8c8c8c",
                    }}
                    defaultValue={eventsFilter.reportType}
                    onChange={(value) => {
                      setEventsFilter((p) => ({
                        ...p,
                        reportType:
                          value as (typeof eventsFilter)["reportType"],
                      }));
                    }}
                  >
                    {[
                      ["All", "All"],
                      ["Processed Reports", "Unbearbeitete Meldungen"],
                      ["Edited Reports", "Bearbeitete Meldungen"],
                    ].map((state, idx) => (
                      <Select.Option key={idx} value={state[0]}>
                        {language === "de" ? state[1] : state[0]}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>)}
            
            {appContext.projects?.droneImageEnabled && (
              <div
                className={Style.topRightLocationIDSwitch}
                style={{
                  background: "#8c8c8c",
                  borderColor: "black",
                  marginTop: "17rem",
                }}
              >
                {language === "de" ? "Flugspuren" : "Drone images"}
                <Switch
                  style={{ margin: "0 .5rem" }}
                  checked={showDroneImages}
                  disabled={!appContext.projects?.id}
                  onChange={async (checked) => {
                    if (checked) {
                      getDroneImages();
                    } else {
                      setShowDroneImages(false);
                    }
                  }}
                />
              </div>
            )}
            {embeddedApp ? null : (
              <div
                className={Style.topRightLocationIDSwitch}
                style={{
                  background: "#8c8c8c",
                  borderColor: "black",
                  marginTop: "19rem",
                }}
              >
                {/* Monitored area */}
                {language === "de" ? "Flugspuren" : "Flight path"}
                <Switch
                  style={{ margin: "0 .5rem" }}
                  checked={showDronePaths}
                  disabled={!appContext.projects?.id}
                  onChange={async (checked) => {
                    if (checked) {
                      getDroneJobs();
                    } else {
                      setShowDronePaths(false);
                    }
                  }}
                />
              </div>
            )}

            {showDronePaths && droneJobs.length && (
              <div
                className={Style.topRightLocationIDSwitch}
                style={{
                  position: "absolute",
                  zIndex: 30,
                  background: "#8c8c8c",
                  borderColor: "black",
                  marginTop: "21rem",
                  display: "flex",
                  paddingBlock: "0.4rem",
                  flexDirection: "column",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {droneJobs.map((d, idx) => (
                  <Checkbox
                    key={idx + d.jobId}
                    style={{ margin: "0 .5rem", color: "white" }}
                    checked={selectedJob?.jobId === d.jobId}
                    value={d.jobId}
                    onChange={async (e) => {
                      console.log(e.target.checked, e.target.value);
                      if (e.target.checked) {
                        setSelectedJob(d);
                        getDronePaths(appContext.projects?.id, d.jobId);
                      } else {
                        setSelectedJob(null);
                        setdronePaths([]);
                      }
                    }}
                  >
                    {d.jobId}
                  </Checkbox>
                ))}
              </div>
            )}
          </div>
        </DeckGL>
      </div>
    </ContextMenu>
  );
}
